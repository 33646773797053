
import PostLayout from "../../layouts/PostLayout";
const Post = () => import("@/views/posts/Post");
const SuccessPayment = () => import("@/views/posts/SuccessPayment");

let defaultRoute = [
    {
        path: '/p',
        component: PostLayout,
        name: 'PostRoot',
        redirect: { name: 'Landing' },
        children: [
            {
                path: ':post_id',
                component: Post,
                name: 'Post', 
            },
            
        ]            
    },
    {
        path: '/success',
        component: PostLayout,
        name: 'PostRoot',
                children: [
            {
                path: '/',
                component: SuccessPayment,
                name: 'SuccessPayment', 
            },
            
        ]            
    }   
    
]
export default []
    .concat(defaultRoute)