<template>
  <div>

    <Header />

    <div class="post-body">
       <div class="post-layout">

          <router-view />

       </div>
    </div>   
        
      
    <Footer :fixed="true"/>   
  </div>
</template>

<script>

import Header from "./PostHeader";
import Footer from './Footer'

export default {
  name: "PostLayout",
  mounted() {},
  components: {     
    Footer, Header
   },
  methods: {
    
  },
  data() {
    return {
      
    };
  },
  computed: {
    
  },
};
</script>

<style scoped>
.post-body{
  margin-top: 58px;
  margin-bottom: 74px;
  display: flex;
  justify-content: center;
  background-color: lightgray;
  min-height: 100vh;  
}
@media (max-width: 419px) {
  .post-body{
    margin-bottom: 142px;
  }  
}
.post-layout {
  max-width: 780px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  overflow: auto;
  padding: 2rem;
  min-height: 100%;
}

</style>
