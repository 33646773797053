import Vue, { nextTick } from "vue";
import VueRouter from "vue-router";
import landing from "./landing/index";
import posts from "./posts/index"; 

Vue.use(VueRouter);

const routes = [
  {
    path: "/",    
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [].concat(posts, landing), 
  },
];



const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    
    if (to.hash) {
      return { selector: to.hash }
    }
  },
  

});

export default router;
