
import MainLayout from "../../layouts/MainLayout";
const Landing = () => import("@/views/landing/Landing");
const PablicOffer = () => import("@/views/landing/PablicOffer");
const PaymentsAgriment = () => import("@/views/landing/PaymentsAgriment");

const SuccessPayment = () => import("@/views/posts/SuccessPayment");

let defaultRoute = [
    {
        path: '/',
        component: MainLayout,
        name: 'Home',
        redirect: { name: 'Landing' },
        children: [
            {
                path: '/',
                component: Landing,
                name: 'Landing', 
            },
            {
                path: '/terms',
                component: PablicOffer,
                name: 'PablicOffer', 
            },
            {
                path: '/payments',
                component: PaymentsAgriment,
                name: 'PaymentsAgriment', 
            },
            {
                path: ':lang',
                component: Landing,
                name: 'Lang_Landing', 
            },            
        ]            
    },   
    
]
export default []
    .concat(defaultRoute)